/* You can add global styles to this file, and also import other style files */

@import 'primeicons/primeicons.css';
@tailwind base;
@tailwind components;
@tailwind utilities;


 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

router-outlet {
  display: block;
  padding-top: 10px ;
}

body {
  overflow-y: hidden;
}

// .min-h-screen {
//   min-height: 90vh;
// }



.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-12-25 18:21:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
    display: none ;

  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    display: block ;

  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
    display: none ;

  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    display: block ;

  }
}


.slide-out-left {
	-webkit-animation: slide-out-left 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-12-25 18:25:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    display: block ;

  }
  100% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
    display: none ;

  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    display: block ;

  }
  100% {
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
    opacity: 0;
    display: none ;

  }
}
